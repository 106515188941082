import {
  Checkbox,
  Label,
  Modal,
  Spinner,
  Textarea,
  TextInput,
} from "flowbite-react";
import { FC, FormEvent, useState } from "react";
import { BiMailSend } from "react-icons/bi";
import styled from "styled-components";
import { MailService } from "../../services/MailService";
import { ShowToast } from "../CodeToast";
import { ContactFormProps } from "./types/index.";

const ContactForm: FC<ContactFormProps> = (props) => {
  const [responseMessage, setResponseMessage] = useState<string | any>("");
  const [sending, setSending] = props.sending;
  const [model, setModel] = props.formData;

  const send = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setSending(true);

      let response = await MailService.sendMail(model);
      if (response.status !== 200) {
        setResponseMessage(response.data ?? response.statusText);
        ShowToast({ message: "Ooops! Ocorreu um erro ao enviar sua solicitação. Por favor, verifique se todos os campos foram preenchidos e tente novamente." });
        return;
      }

      ShowToast({ message: "Sua solicitação foi enviada! Em breve um consultor entrará em contato." });
      props.onClose?.call(this);
    } catch (error) {
      ShowToast({ message: "Ooops! Ocorreu um erro ao enviar sua solicitação. Por favor, verifique se todos os campos foram preenchidos e tente novamente." });
      console.log(error);
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal show={props.show} onClose={props.onClose} size="4xl">
      <Modal.Header>
        <span>Formulário de contato</span>
      </Modal.Header>

      <StyledForm method="post" onSubmit={async (e) => await send(e)}>
        <div className="overflow-y-auto max-h-80 md:max-h-96 px-2">
          <div className="grid grid-cols-12">
            <div className="form-control mt-5 col-span-12">
              <div className="mb-1">
                <Label htmlFor="inputNome" value="Nome:" />
              </div>
              <TextInput
                id="inputNome"
                type="text"
                required={true}
                value={model?.nome}
                onChange={(e) =>
                  setModel({ ...model, nome: e.currentTarget.value })
                }
                placeholder="Nome Completo"
                maxLength={200}
              />
            </div>

            <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
              <div className="mb-1">
                <Label htmlFor="inputTelefone" value="Celular/Fixo:" />
              </div>
              <TextInput
                id="inputTelefone"
                type="text"
                required={true}
                value={model?.telefone}
                onChange={(e) =>
                  setModel({ ...model, telefone: e.currentTarget.value })
                }
                placeholder="(DDD) 9XXXX-XXXX"
                maxLength={140}
              />
            </div>


            <div className="form-control mt-5 col-span-12 md:col-span-6">
              <div className="mb-1">
                <Label htmlFor="inputEmail" value="E-mail:" />
              </div>
              <TextInput
                id="inputEmail"
                type="email"
                addon="@"
                required={true}
                value={model?.email}
                onChange={(e) =>
                  setModel({ ...model, email: e.currentTarget.value })
                }
                placeholder="Seu melhor e-mail"
                maxLength={400}
              />
            </div>

            <div className="form-control mt-5 col-span-12">
              <div className="mb-1">
                <Label htmlFor="inputMensagem" value="Mensagem:" />
              </div>
              <Textarea
                id="inputMensagem"
                value={model?.mensagem}
                onChange={(e) =>
                  setModel({ ...model, mensagem: e.currentTarget.value })
                }
                placeholder="Digite sua mensagem"
                maxLength={5000}
              />
            </div>

            <div className="form-control mt-8 col-span-12 flex space-x-2">
              <Checkbox
                id="checkBoxLGPD"
                checked={model?.lgpd}
                required={true}
                onChange={(e) =>
                  setModel({ ...model, lgpd: e.currentTarget.checked })
                }
                placeholder="Checked"
              ></Checkbox>

              <Label
                htmlFor="checkBoxLGPD"
                value="Eu permito que a Code armazene e processe meus dados pessoais."
              />
            </div>

            <div className="mt-8 col-span-12 space-x-2">

            </div>
          </div>

          <div className="flex mt-8 ">
            <span className="mt-3 mr-auto text-sm text-red-600">
              {responseMessage}
            </span>
          </div>

        </div>
        <footer className="p-3 border-t-2 flex justify-center items-center">
          <button
            type="submit"
            disabled={sending}
            className="pl-3 pr-4 py-2 text-base font-medium text-center text-slate-700 rounded-lg bg-yellow-200 hover:bg-yellow-300 focus:bg-yellow-400 focus:ring-0 ml-auto"
          >
            <div hidden={!sending}>
              <div className="inline-flex items-center justify-center align-middle">
                <Spinner size="sm" light={true} />{" "}
                <span className="ml-2">Enviar</span>
              </div>
            </div>

            <div hidden={sending}>
              <div className="inline-flex items-center justify-center align-middle">
                <BiMailSend />
                <span className="ml-2 text-sm">Enviar</span>
              </div>
            </div>
          </button>
        </footer>
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled.form`
  input:focus,
  select:focus,
  textarea:focus {
    border-width: 2px;
    border-color: rgb(250, 202, 21);
    box-shadow: 0 8px 12px -2.5px rgba(250, 202, 21, 0.6);
  }

  input[type="checkbox"] {
    cursor: pointer;
  }

  input[type="checkbox"]:checked {
    background-color: rgb(250, 202, 21);
  }

  button:disabled {
    cursor: no-drop;
    background-color: rgba(250, 202, 21, 0.55);
  }

  padding: 0.75rem 1.5rem;
`;

export default ContactForm;
