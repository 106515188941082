import { FC } from "react";
import {
    FaInstagram,
    FaFacebook,
    FaLinkedinIn as FaLinkedin,
    FaYoutube,
    FaWhatsapp,
} from "react-icons/fa";

export const Footer: FC = () => {
    const currentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <footer className="p-6 pb-8 md:p-4 md:pb-4 bg-sky-500">
            <div className="mx-auto max-w-screen-xl">
                <div className="md:flex md:items-center md:justify-between">
                    <span className="text-sm text-white text-center md:text-start break-words max-w-xs">
                        Rua Rio Grande do Norte, 1435, sala 708 - CEP 30130-138 -
                        Funcionários - Belo Horizonte/MG
                    </span>

                    <div className="flex mb-8 md:mb-0 md:block md:justify-self-auto justify-center place-items-center items-center text-center">
                        <span className="text-sm text-white sm:text-center">
                            <br />
                            {currentYear()}
                            {" © "}
                            <a
                                href="https://codeinformatica.com.br"
                                className="hover:underline font-semibold text-yellow-200"
                            >
                                Code Informática e Tecnologia LTDA
                            </a>
                        </span>
                    </div>

                    <div className="flex mt-4 space-x-6 sm:mt-0 justify-center">
                        <a
                            href="https://www.instagram.com/code.informatica/"
                            rel="noreferrer"
                            target="_blank"
                            className="text-slate-800 rounded-full bg-yellow-200 p-3 hover:bg-pink-600 hover:text-white"
                        >
                            <FaInstagram />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/code-inform%C3%A1tica-e-tecnologia/"
                            rel="noreferrer"
                            target="_blank"
                            className="text-slate-800 rounded-full bg-yellow-200 p-3 hover:bg-sky-500 hover:text-white"
                        >
                            <FaLinkedin />
                        </a>
                        <a
                            href="https://www.youtube.com/@codeinformaticaetecnologia"
                            rel="noreferrer"
                            target="_blank"
                            className="text-slate-800 rounded-full bg-yellow-200 p-3 hover:bg-red-500 hover:text-white"
                        >
                            <FaYoutube />
                        </a>
                        <a
                            href="https://www.facebook.com/codeinformatica.oficial"
                            rel="noreferrer"
                            target="_blank"
                            className="text-slate-800 rounded-full bg-yellow-200 p-3 hover:bg-blue-500 hover:text-white"
                        >
                            <FaFacebook />
                        </a>

                        <a
                            href="https://api.whatsapp.com/send?phone=5531971361592&text=Ol%C3%A1%21%20Gostaria%20de%20fazer%20uma%20cota%C3%A7%C3%A3o.&lang=pt_br"
                            rel="noreferrer"
                            target="_blank"
                            className="text-slate-800 rounded-full bg-yellow-200 p-3 hover:bg-green-500 hover:text-white"
                        >
                            <FaWhatsapp />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};